<template>
  <div class="essayDetail">
    <h3>第一次做饭范文</h3>
    <p>
      在人的一生当中有许许多多的“第一次”，有时那些难忘的“第一次”可能会使你受益终身。在我的印象中，使我最难忘的是第一次做饭。
那是很久以前的事了，虽说是这样，但我至今也难以忘怀。那一天，爸爸妈妈很早就出去有事了。我醒来后，发现他们在我的床头柜
前留了一张字条和一盒饼干。字条上说他们今天中午才回来，特地留了一盒饼干给我吃。
对于以前贪吃的我来说，一盒饼干一会儿就吃完了。我勉强熬了一个早上，到了中午的时候，饿得受不了了，怎么办呢，只好自己做饭
了。我来到了厨房，发现只有一堆菠菜和几个土豆。我心想天天吃着爸爸妈妈做的饭，今天我来给他们做一顿饭吧，让他们也高兴高兴
，想着想着心里觉得美滋滋的。
于是，我学着大人的样子，先把饭蒸好。我不知道该放几勺米，于是回想起妈妈每次饭蒸熟之后，饭总是鼓鼓的。“肯定放了很多米。
”我越想越有把握，于是放了5勺米。我想妈妈每次好象用水把米淘了几遍。于是我也学着她的样子淘了一遍，其实就是冲了一下，然后
就加水，结果把电饭煲给加满了。
接下来，我就来切菜，我想：把土豆切得越大，吃的就越饱。于是，我一个土豆只切一刀，切完后，我便开始炸。我先把液化气的开关
给扭到最大，然后扭开打火灶。突然一股无名之火直冲房顶，我赶紧扭小开关，然后把油倒进锅里。只见锅里一炸一炸的，差点就把手
给烫伤了。至于菠菜嘛，我就直接往电饭煲里倒。因为我是这样想的：平时爸爸妈妈都没有吃过蒸菠菜，今天我给他们做点吃，他们一
定会表扬我的，我越想越开心。突然，一股烧焦的气扑鼻而来。我一看锅里，土豆糊了。我赶紧想办法把它盛到盘子里，电饭煲也拔掉
了插头，都放到了餐桌上。
说来也巧，爸爸妈妈这时正好回家了。我连忙上前去告诉他们我做了饭。他们感到很惊奇，可是上前来一看便傻了眼：一块块比碳还黑
比苹果还大的土豆;比稀饭还稀的饭上飘着几片叶子……
爸爸欣慰的笑着，摸了摸我的头说，还不拿碗筷来呀，我可是馋得不行，急着要尝尝这美味佳肴了。
这顿饭过去已好久了，我却依然还能嚼出许许多多的味来……

    </p>
  </div>
</template>

<script>
export default {

}
</script>

<style>
.essayDetail{
  width: 1120px;
  background:rgba(255,255,255,1);
  box-shadow:0px 14px 30px 0px rgba(236,235,235,1);
  margin:  30px auto;
  padding: 35px 23px;
  box-sizing: border-box;
}
.essayDetail h3{
  font-size:36px;
  font-family:MicrosoftYaHeiUI-Bold,MicrosoftYaHeiUI;
  font-weight:bold;
  color:rgba(68,68,68,1);
  margin-bottom: 20px;
  text-align: left;
  
}
.essayDetail p{
  font-size:18px;
  font-family:MicrosoftYaHeiUI;
  color:rgba(68,68,68,1);
  line-height:30px;
  text-align: left;
  padding-bottom: 92px;
}
</style>